<template>
<div class="activation-list">
  <div class="search">
    <div class="search-l">
      <div class="search-l-item">
        <p>激活状态:</p>
        <Select v-model="statusId" style="width:200px" clearable placeholder="请选择激活状态" @on-change="changeSearch">
          <Option v-for="item in statusList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div class="search-l-item">
        <p>激活时间:</p>
        <DatePicker v-model="searchDate" type="daterange" format="yyyy-MM-dd" show-week-numbers placement="bottom-end" placeholder="请选择时间" style="width: 200px" @on-change="changeSearch"></DatePicker>
      </div>
    </div>
    <div class="search-r">
      <Input v-model="keyword"
             :placeholder="$t('common_search_keyword')"
             clearable
             search
             :enter-button="true"
             @on-search="changeKeyword"
             style="width: 200px;"
      />
      <Button type="primary" style="margin-left: 10px" @click="exportData" v-if="dataId">导出</Button>
      <Button type="primary" style="margin-left: 10px" @click="addUser" v-if="isSuper">添加成员</Button>
    </div>
  </div>
  <div class="table">
    <Table border :columns="columns" :data="dataList"></Table>
  </div>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
  <Modal
      v-model="changeCategoryModal"
      :title="$t('codekey_detail_change_category')"
      v-if="curData"
  >
    <div class="add-container">
      <Form :label-width="100" class="company-h" >
        <FormItem :label="$t('codekey_detail_cur_active_category') + ':'">
          {{curData.category && curData.category.name}}
        </FormItem>
        <FormItem :label="$t('codekey_detail_change_category') + ':'"  >
          <div class="category-list">
            <RadioGroup v-model="category">
              <Radio :label="item.name" v-for="item in categoryList" :key="item.id"></Radio>
            </RadioGroup>
          </div>
        </FormItem>
        <FormItem :label="$t('codekey_detail_confirm_category') + ':'">
          <div class="category-list">
            {{category}}
          </div>
        </FormItem>
      </Form>
    </div>

    <div slot="footer">
      <Button @click="cancel">{{ $t('codekey_manage_cancel') }}</Button>
      <Button type="primary" @click="save">{{ $t('codekey_manage_save') }}</Button>
      <!--        <Button type="primary" @click="saveUser">导出激活码</Button>-->
    </div>
  </Modal>
  <div v-if="addUserModal">
    <AddUser :dataDeatil="dataDeatil" @success="addSuccess" @close="closeAdduser"></AddUser>
  </div>

</div>
</template>

<script>
import util from '@/utils/tools.js';
import AddUser from '../addUser';
export default {
  name: "userList",
  data(){
    return {
      keyword:'',
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '组织名称',
          render: (h, params) => {
            return h('div', {}, params.row.site ? params.row.site.name + '(ID:'+params.row.site_id+')' : '' );
          }
        },
        {
          title: '用户',
          key: 'age',
          render: (h, params) => {

            return h('router-link', {
              props: {
                to: {
                  name: this.isSuper ? 'user/detail' : 'manager/user/detail',
                  params: {
                    id: params.row.user && params.row.user.id,
                    tab: 'index'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.user ? (params.row.user.nickname + '(ID:' + params.row.user.id +')') : '');
          }
        },
        // {
        //   title: this.$t('codekey_detail_table_name'),
        //   key: 'age',
        //   render: (h, params) => {
        //     return h('div', {}, params.row.user && params.row.user.nickname);
        //   }
        // },
        {
          title: this.$t('codekey_detail_active_dataset1'),
          render: (h, params) => {
            return h('div', {}, params.row.firstCategory && params.row.firstCategory.name);
          }
        },
        {
          title: this.$t('codekey_detail_active_dataset2'),
          render: (h, params) => {
            return h('div', {}, params.row.secondCategory && params.row.secondCategory.name);
          }
        },
        {
          title: this.$t('codekey_detail_code'),
          render: (h, params) => {
            return h('div', {}, params.row.code);
          }
        },
        {
          title: this.$t('codekey_detail_active_dataset'),
          render: (h, params) => {
            return h('div', {}, params.row.category && params.row.category.name);
          }
        },
        {
          title: this.$t('codekey_detail_active_time'),
          key: 'age',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.active_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_detail_remaining_time'),
          key: 'timelong',
          render: (h, params) => {
            let oDate = new Date().getTime();
            let len = params.row.active_time * 1000 - oDate + (params.row.timelong * 1000);
            let time = util.timeFormate((params.row.active_time * 1000 - oDate + (params.row.timelong * 1000))/ 1000);

            let result='';
            if(params.row.active_time != '0'){
              if(len > 0){
                result = time.days + '天' + time.hours + '小时' + time.minutes + '分钟' + time.seconds + '秒'
              }else{
                result = 0;
              }
            }else{
              result = this.timelongsObj[params.row.timelong];
            }
            return h('div', {}, result);
          }
        },
        {
          title: this.$t('codekey_detail_status'),
          key: 'age',
          render: (h, params) => {
            return h('div', {}, this.statusObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_detail_op_user'),
          key: 'address',
          render: (h, params) => {
            return h('span', params.row.creator && params.row.creator.nickname);
          }
        },
        {
          title: this.$t('codekey_detail_create_time'),
          key: 'status',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_detail_op'),
          key: 'action',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.isSuper ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.status != 1
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.changeCategory();
                  }
                }
              }, this.$t('codekey_detail_change_category')) : '',
              h('Select', {
                    props:{
                      transfer: true,//不受表格高度影响,
                      placeholder:this.$t('codekey_detail_more'),
                      size: 'small',
                      clearable:true,
                    },
                    style:{
                      width:'60px',
                      marginLeft:'5px'
                    },
                    on: {
                      'on-change':(event) => {
                        if(event == 'disabled'){  //弃用
                          this.curData = params.row;
                          this.forbidden();
                        }
                      }
                    },
                  },
                  [
                    h('Option',{
                      props: {
                        value: 'disabled'
                      }
                    },this.$t('codekey_detail_disabled')),
                  ])
            ]);
          }
        },
      ],
      columnsW: [
        {
          title: 'ID',
          key: 'id',
          width:80,
          fixed:"left"
        },
        {
          title: '组织名称',
          fixed:"left",
          minWidth:200,
          render: (h, params) => {
            return h('div', {}, params.row.site ? params.row.site.name + '(ID:'+params.row.id+')' : '' );
          }
        },
        {
          title: '激活码授权',
          minWidth:80,
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: this.isSuper ? 'cdkey-managedetail' : 'cdkey-detail',
                  params: {
                    id: params.row.cdkey_id,
                    tab:'list'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.cdkey && params.row.cdkey.id);
          }
        },
        {
          title: '用户',
          key: 'age',
          minWidth:100,
          render: (h, params) => {
            console.log(this.isSuper,'this.isSuperthis.isSuper')
            return h('router-link', {
              props: {
                to: {
                  name: this.isSuper ? 'user/detail' : 'manager/user/detail',
                  params: {
                    id: params.row.user_id,
                    tab: 'index'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.user ? (params.row.user.nickname + '(ID:' + params.row.user.id +')') : '');
          }
        },
        // {
        //   title: this.$t('codekey_detail_table_name'),
        //   key: 'age',
        //   render: (h, params) => {
        //     return h('div', {}, params.row.user && params.row.user.nickname);
        //   }
        // },
        {
          title: this.$t('codekey_detail_active_dataset1'),
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.firstCategory && params.row.firstCategory.name);
          }
        },
        {
          title: this.$t('codekey_detail_active_dataset2'),
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.secondCategory && params.row.secondCategory.name);
          }
        },
        {
          title: this.$t('codekey_detail_code'),
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.code);
          }
        },
        {
          title: this.$t('codekey_detail_active_dataset'),
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.category && params.row.category.name);
          }
        },
        {
          title: this.$t('codekey_detail_active_time'),
          key: 'age',
          minWidth:100,
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.active_time * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_detail_remaining_time'),
          key: 'timelong',
          minWidth:100,
          render: (h, params) => {
            let oDate = new Date().getTime();
            let len = params.row.active_time * 1000 - oDate + (params.row.timelong * 1000);
            let time = util.timeFormate((params.row.active_time * 1000 - oDate + (params.row.timelong * 1000))/ 1000);

            let result='';
            if(params.row.active_time != '0'){
              if(len > 0){
                result = time.days + '天' + time.hours + '小时' + time.minutes + '分钟' + time.seconds + '秒'
              }else{
                result = 0;
              }
            }else{
              result = this.timelongsObj[params.row.timelong];
            }
            return h('div', {}, result);
          }
        },
        {
          title: this.$t('codekey_detail_status'),
          key: 'age',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, this.statusObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_detail_op_user'),
          key: 'address',
          minWidth:100,
          render: (h, params) => {
            return h('span', params.row.creator && params.row.creator.nickname);
          }
        },
        {
          title: this.$t('codekey_detail_create_time'),
          key: 'status',
          minWidth:100,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_detail_op'),
          key: 'action',
          width: 220,
          align: 'center',
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              this.isSuper ? h('Button', {
                props: {
                  type: 'primary',
                  // size: 'small',
                  disabled:params.row.status != 1
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.changeCategory();
                  }
                }
              }, this.$t('codekey_detail_change_category')) : '',
              h('el-select', {
                    props:{
                      transfer: true,//不受表格高度影响,
                      placeholder:this.$t('codekey_detail_more'),
                      size: 'mini',
                      clearable:true,
                      value:'',
                    },
                    style:{
                      width:'75px',
                      marginLeft:'5px'
                    },
                    on: {
                      'change':(event) => {
                        if(event == 'disabled'){  //弃用
                          this.curData = params.row;
                          this.forbidden();
                        }
                      }
                    },
                  },
                  [
                    h('el-option',{
                      props: {
                        value: 'disabled'
                      }
                    },this.$t('codekey_detail_disabled')),
                  ])
            ]);
          }
        },
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      statusObj:{},
      timelongsObj:{},
      statusList:[],
      changeCategoryModal:false,
      curData:{},
      cdKey:{},
      categoryList:[],
      category:'',
      dataId:'',
      statusId:'',
      searchDate:[],
      detailName:'',
      addUserModal:false,
    }
  },
  props:{
    isManage:{
      type:Boolean,
      default:false,
    },
    isSuper:{
      type:Boolean,
      default:false,
    },
    isList:{
      type:Boolean,
      default:false,
    },
    dataDeatil:{
      type:Object,
      default:()=>{
        return {}
      }
    },

  },
  components:{
    AddUser
  },
  created(){
    this.dataId = this.$route.params.id || this.dataDeatil.id;
    this.getList();
    if(this.isList){
      this.columns = this.columnsW;
      if(this.isSuper){
        this.detailName = 'cdkey-managedetail'
      }
      if(this.isManage){
        this.detailName = 'cdkey-detail'
      }
    }
  },
  methods:{
    addUser(){
      this.addUserModal = true;
    },
    changeSearch(){
      this.page = 1;
      this.getList();
    },
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        cdkey_id:this.dataId,
      };
      if (this.isManage){
        params['site_id'] = 0;
        params['user_id'] = -1;
      }
      if (this.isSuper){
        params['site_id'] = -1;
        params['user_id'] = -1;
      }
      if(this.statusId){
        params.status = this.statusId;
      }
      if(this.searchDate.length){
        params.active_time_start = new Date(this.searchDate[0]).getTime()/1000;
        params.active_time_end = new Date(this.searchDate[1]).getTime()/1000;
      }
      this.api.codekey.cdkeyCodeList(params).then((res)=>{
        this.cdKey = res.cdkey;
        this.dataList = res.list;
        this.statusObj = res.statuses;

        this.statusList = [];
        for(let name in this.statusObj){
          this.statusList.push({
            label:this.statusObj[name],
            value:name
          })
        }

        this.timelongsObj = res.timelongs;
        this.total = Number(res.count);

      })
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    getCategory(){
      let params = {
        category_id:this.curData.first_category_id,
        category_second_id:this.curData.second_category_id
      };

      this.api.dataset.categoryForm(params).then((res)=>{
        this.categoryList = res.categoryThirdList;
        this.category = this.categoryList[0].name;
      })
    },
    changeCategory(){
      this.getCategory();
      this.changeCategoryModal = true;
    },
    cancel(){
      this.changeCategoryModal = false;
    },
    save(){
      let categoryId = this.categoryList.filter((item)=>{
        return item.name == this.category;
      })[0].id
      let params = {
        cdkey_id:this.dataId,
        cdkey_code_id:this.curData.id,
        category_id:categoryId,
      };
      if (this.isManage){
        params['site_id'] = 0;
        params['user_id'] = -1;
      }
      if (this.isSuper){
        params['site_id'] = -1;
        params['user_id'] = -1;
      }
      this.api.codekey.cdkeyCodeChange(params).then((res)=>{
        this.changeCategoryModal = false;
        this.getList();
      })
    },
    forbidden(){  //禁用
      let params = {
        cdkey_id:this.dataId,
        code_id:this.curData.id
      };
      if (this.isManage){
        params['site_id'] = 0;
        params['user_id'] = -1;
      }
      if (this.isSuper){
        params['site_id'] = -1;
        params['user_id'] = -1;
      }
      this.api.codekey.cdkeyCodeDisable(params).then((res)=>{
        this.$Message.success(this.$t('codekey_detail_disabled_success'));
        this.getList();
      })
    },
    exportData(){ //导出
      let params = {
        cdkey_id:this.dataId,
      };
      if (this.isManage){
        params['site_id'] = 0;
        params['user_id'] = -1;
      }
      if (this.isSuper){
        params['site_id'] = -1;
        params['user_id'] = -1;
      }
      if(this.statusId){
        params.status = this.statusId;
      }
      if(this.searchDate.length){
        params.active_time_start = new Date(this.searchDate[0]).getTime()/1000;
        params.active_time_end = new Date(this.searchDate[1]).getTime()/1000;
      }
      this.api.codekey.cdkeyCodeExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res)
        })
      })
    },
    addSuccess(){
      this.page = 1;
      this.getList();
      this.addUserModal = false;
    },
    closeAdduser(){
      this.addUserModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
.activation-list{
  .search{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .search-l{
      display: flex;
      justify-content: flex-start;
      .search-l-item{
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p{
          margin-right: 10px;
        }
      }
    }
    .search-r{
      display: flex;
      justify-content: flex-start;
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
<style>
.activation-list .table .el-input--mini .el-input__inner{
  height: 31px !important;
  line-height: 31px !important;
}
</style>
