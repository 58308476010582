<template>
<div>
  <!-- <Modal
      v-model="addUserModal"
      :title="$t('codekey_list_add_user')"
      width="1200px"
      v-if="curData"
      @on-cancel="cancelUserModal"
  >
    <div class="add-container">
      <div class="tit">
        <p>{{$t('codekey_list_surplus')}}：<span>{{ curData.amount - curData.count }}个/{{this.timelongsObj[curData.timelong]}}</span></p>
      </div>
      <Form :label-width="100" class="company-h" >
        <FormItem :label="$t('codekey_manage_add_first_category') + ':'" v-if="curData.category_id">
          <div class="category-list">
            <div :class="firstCategoryId == item.id ? 'active' : ''" v-for="item in firstCategoryList" :key="item.id + 'first'" @click="changeFirstCategory(item)">{{item.name}}</div>
          </div>
        </FormItem>
        <FormItem :label="$t('codekey_manage_add_second_category') + ':'">
          <div class="category-list">
            <div :class="secondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'first'" @click="changeSecondCategory(item)">{{item.name}}</div>
          </div>
        </FormItem>
        <FormItem :label="$t('codekey_list_select_dateset') + ':'">
          <div class="category-list">
            <RadioGroup v-model="userCategory"  @on-change="changeThirdCategory">
              <Radio :label="item.id" v-for="item in categoryList" :key="item.id">{{item.name}}</Radio>
            </RadioGroup>
          </div>
        </FormItem>
        <FormItem :label="$t('codekey_list_mode') + ':'" >
          <div class="mode">
            <div class="mode-tit">
              <RadioGroup v-model="userMode">
                <Radio :label="item" v-for="(item,index) in modeList" :key="index" ></Radio>
              </RadioGroup>
            </div>
            <div class="mode-cont" v-if="userMode == $t('codekey_list_select_user')">
              <div class="mode-cont-tit">
                <div>
                  <Button type="primary" @click="goInvitation">{{ $t('codekey_list_copy_url') }}</Button>
                  <p>{{ $t('codekey_list_selected_user') }}:<span>{{allUserData.length}}</span>人</p>
                </div>
                <div class="mode-tit" style="display: flex;justify-content: flex-start;align-items: center">
                  <span style="font-weight: bold;margin-right: 5px">是否分发:</span>
                  <RadioGroup v-model="distribute" @on-change="changeDistribute">
                    <Radio :label="item.id" v-for="(item,index) in distributeList" :key="index" >{{item.name}}</Radio>
                  </RadioGroup>
                </div>
                <div class="mode-cont-r">
                  <div class="group-box">
                    <p>班级</p>
                    <Select v-model="groupId" style="width:200px" clearable @on-change="changeGroup">
                      <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                  </div>
                  <Input v-model="userKeyword"
                         :placeholder="$t('common_search_keyword')"
                         clearable
                         search
                         :enter-button="true"
                         @on-enter="changeUserKeyword"
                         @on-search="changeUserKeyword"
                         style="width: 200px;"
                  />
                </div>

              </div>
              <div class="table">
                <Table border :columns="userColumns" :data="userDataList" @on-select="selectUser" @on-select-cancel="cancelUser" @on-select-all="selectUser" @on-select-all-cancel="cancelUserAll"></Table>
              </div>
              <div class="page">
                <MyPage :total="userTotal" :current="userPage" @changePage="changeUserPage" @changePageSize="changeUserPageSize"></MyPage>
              </div>
            </div>
            <div class="mode-cont" v-if="userMode == $t('codekey_list_issue')">
              {{ $t('codekey_list_need_code_num') }}：<Input v-model="count" type="number" :placeholder="$t('codekey_list_num_placeholder')" style="width: 300px" />
            </div>
          </div>
        </FormItem>
      </Form>
    </div>

    <div slot="footer">
      <Button @click="cancelUserModal">{{ $t('codekey_manage_cancel') }}</Button>
      <Button type="primary" @click="saveUser">{{ $t('codekey_manage_save') }}</Button>
    </div>
  </Modal> -->

  <el-drawer :visible.sync="addUserModal" :size="1200" @close="cancelUserModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">{{ $t('codekey_list_add_user') }}</div>
      <div class="drawer-title-btns">
        <Button @click="cancelUserModal" style="margin-right: 10px;">{{ $t('codekey_manage_cancel') }}</Button>
        <Button type="primary" @click="saveUser">{{ $t('codekey_manage_save') }}</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="add-container">
        <div class="tit">
          <p>{{$t('codekey_list_surplus')}}：<span>{{ curData.amount - curData.count }}个/{{this.timelongsObj[curData.timelong]}}</span></p>
        </div>
        <Form :label-width="100" class="company-h" >
          <FormItem :label="$t('codekey_manage_add_first_category') + ':'" v-if="curData.category_id">
            <div class="category-list">
              <div :class="firstCategoryId == item.id ? 'active' : ''" v-for="item in firstCategoryList" :key="item.id + 'first'" @click="changeFirstCategory(item)">{{item.name}}</div>
            </div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_second_category') + ':'">
            <div class="category-list">
              <div :class="secondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'first'" @click="changeSecondCategory(item)">{{item.name}}</div>
            </div>
          </FormItem>
          <FormItem :label="$t('codekey_list_select_dateset') + ':'">
            <div class="category-list">
              <RadioGroup v-model="userCategory"  @on-change="changeThirdCategory">
                <Radio :label="item.id" v-for="item in categoryList" :key="item.id">{{item.name}}</Radio>
              </RadioGroup>
            </div>
          </FormItem>
          <FormItem :label="$t('codekey_list_mode') + ':'" >
            <div class="mode">
              <div class="mode-tit">
                <RadioGroup v-model="userMode">
                  <Radio :label="item" v-for="(item,index) in modeList" :key="index" ></Radio>
                </RadioGroup>
              </div>
              <div class="mode-cont" v-if="userMode == $t('codekey_list_select_user')">
                <div class="mode-cont-tit">
                  <div>
<!--                    <Button type="primary" @click="goInvitation">{{ $t('codekey_list_copy_url') }}</Button>-->
                    <p>{{ $t('codekey_list_selected_user') }}:<span>{{allUserData.length}}</span>人</p>
                  </div>
                  <div class="mode-tit" style="display: flex;justify-content: flex-start;align-items: center">
                    <span style="font-weight: bold;margin-right: 5px">是否分发:</span>
                    <RadioGroup v-model="distribute" @on-change="changeDistribute">
                      <Radio :label="item.id" v-for="(item,index) in distributeList" :key="index" >{{item.name}}</Radio>
                    </RadioGroup>
                  </div>
                  <div class="mode-cont-r">
                    <div class="group-box">
                      <p>班级</p>
                      <Select v-model="groupId" style="width:200px" clearable @on-change="changeGroup">
                        <Option v-for="item in groupList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                      </Select>
                    </div>
                    <Input v-model="userKeyword"
                          :placeholder="$t('common_search_keyword')"
                          clearable
                          search
                          :enter-button="true"
                          @on-enter="changeUserKeyword"
                          @on-search="changeUserKeyword"
                          style="width: 200px;"
                    />
                  </div>

                </div>
                <div class="table">
                  <Table border :columns="userColumns" :data="userDataList" @on-select="selectUser" @on-select-cancel="cancelUser" @on-select-all="selectUser" @on-select-all-cancel="cancelUserAll"></Table>
                </div>
                <div class="page">
                  <MyPage :total="userTotal" :current="userPage" @changePage="changeUserPage" @changePageSize="changeUserPageSize"></MyPage>

                </div>
              </div>
              <div class="mode-cont" v-if="userMode == $t('codekey_list_issue')">
                {{ $t('codekey_list_need_code_num') }}：<Input v-model="count" type="number" :placeholder="$t('codekey_list_num_placeholder')" style="width: 300px" />
              </div>
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
  </el-drawer>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "addUser.vue",
  data(){
    return {
      addUserModal:true,
      curData:'',
      timelongsObj:{},
      categoryList:[],
      userColumns:[
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_list_name'),
          key: 'nickname'
        },
        {
          title: this.$t('codekey_list_company'),
          key: 'id',
          render: (h, params) => {
            return h('div', {}, params.row.site.name);
          }
        },
      ],
      userDataList:[],
      userPage:1,
      userPageSize:10,
      userTotal:0,
      userKeyword:'',
      userCategory:'',
      userMode:this.$t('codekey_list_select_user'),
      modeList:[this.$t('codekey_list_select_user'),this.$t('codekey_list_issue')],
      allUserData:[],
      count:'',
      firstCategoryList:[], //一级分类列表
      secondCategoryList:[],  //二级分类列表
      firstCategoryId:'',
      secondCategoryId:'',
      groupList:[],
      groupId:'',
      distributeList:[
        {
          name:'全部',
          id:0
        },
        {
          name:'未分发',
          id:1
        },
        {
          name:'已分发',
          id:2
        },
      ],
      distribute:0
    }
  },
  props:{
    dataDeatil:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    isSuper:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    dataDeatil:{
      handler(newV){
        if(newV.id){
          this.init(newV);
        }

      },
      immediate:true
    }
  },
  created(){

  },
  methods:{
    init(data){
      this.addUserModal = true;
      this.userMode = this.$t('codekey_list_select_user');
      this.allUserData = [];
      this.count = '';
      this.curData = data;
      this.firstCategoryId = data.category_id;
      this.secondCategoryId = data.second_category_id;

      this.getGroupList();
      this.getCategory(this.firstCategoryId,this.secondCategoryId);
      console.log(this.firstCategoryId,this.secondCategoryId,data,'data+++++++')

    },
    getGroupList(){
      let params = {
        pageSize:99,
        user_id:'-1',
        site_id:this.dataDeatil.site.id,
      };

      this.api.user.groupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    changeGroup(){
      this.userPage = 1;
      this.getUserList();
    },
    changeDistribute(){
      this.userPage = 1;
      this.getUserList();
    },
    getCategory(firstCategoryId,secondCategoryId){
      let params = {
        type:"0"
      };
      if(firstCategoryId){
        params.category_id = firstCategoryId;
      }
      if(secondCategoryId){
        params.category_second_id = secondCategoryId;
      }
      if(this.isSuper){
        params.site_id = '-1';
        params.user_id = '-1';
      }

      this.api.dataset.categoryForm(params).then((res)=>{
        this.firstCategoryList = res.categoryFirstList;
        this.secondCategoryList = res.categorySecondList;

        this.categoryList =[
          {
            name:'全部',
            id:'0',
          },
          ...res.categoryThirdList
        ]

        // this.categoryList = res.categoryThirdList;

        if(firstCategoryId == '0'){
          this.firstCategoryId = res.categoryFirstList.length && res.categoryFirstList[0].id;
          this.getCategory(this.firstCategoryId,this.secondCategoryId);
        }

        if(firstCategoryId != '0' && secondCategoryId == '0'){
          this.secondCategoryId = res.categorySecondList.length && res.categorySecondList[0].id;
          this.getCategory(this.firstCategoryId,this.secondCategoryId);
        }

        if (firstCategoryId != '0' && secondCategoryId != '0'){
          this.userCategory = this.categoryList.length && this.categoryList[0].id;
          this.getUserList();
        }

      })
    },
    getUserList(){
      let params = {
        site_id:this.dataDeatil.site.id,
        user_id:-1,
        page:this.userPage,
        pageSize:this.userPageSize,
        keyword: this.userKeyword,
        group_id:this.groupId,
        cdkey_given:this.distribute
      };
      if(this.firstCategoryId){
        params.cdkey_given_first_category_id = this.firstCategoryId;
      }
      if(this.secondCategoryId){
        params.cdkey_given_second_category_id = this.secondCategoryId;
      }
      if(this.userCategory){
        params.cdkey_given_category_id = this.userCategory;
      }


      this.api.user.userList(params).then((res)=>{
        this.userDataList = res.list;
        this.userDataList.forEach((item)=>{
          let dataIndex = this.allUserData.findIndex((sItem)=>{
            return sItem.id == item.id;
          });

          if(dataIndex != -1){
            item._checked = true;
          }
        });
        this.userTotal = Number(res.count);
      })
    },
    changeUserKeyword(){
      this.userPage = 1;
      this.getUserList();
    },
    changeUserPage(page){
      this.userPage = page;
      this.getUserList();
    },
    changeUserPageSize(pageSize){
      this.userPageSize = pageSize;
      this.getUserList();
    },
    selectUser(data){
      data.forEach((item)=>{
        let index = this.allUserData.findIndex((sItem)=>{
          return sItem.id == item.id;
        });
        if(index == -1){
          this.allUserData.push(item);
        }
      });
    },
    cancelUser(row,data){
      let dataIndex = this.allUserData.findIndex((sItem)=>{
        return sItem.id == data.id;
      });
      if(dataIndex != -1){
        this.$delete(this.allUserData,dataIndex)
        // this.allUserData.splice(dataIndex,1);
      }
    },
    cancelUserAll(){
      this.userDataList.forEach((item,index)=>{
        let dataIndex = this.allUserData.findIndex((sItem)=>{
          return sItem.id == item.id;
        });
        if(dataIndex != -1){
          this.$delete(this.allUserData,dataIndex)
          // this.allUserData.splice(index,1);
        }
      })
    },
    saveUser(){
      let categoryId = this.userCategory;

      let params = {
        cdkey_id:this.curData.id,
        site_id:this.dataDeatil.site.id,
        // category_id:categoryId,
      };
      if(categoryId == '0'){
        params.second_category_id = this.secondCategoryId;
      }else{
        params.category_id = categoryId;
      }
      let len = this.curData.amount - this.curData.count;

      if(this.userMode == this.$t('codekey_list_select_user')){
        if(!this.allUserData.length){
          this.$Message.warning(this.$t('codekey_list_select_user_message'));
          return;
        }
        if(this.allUserData.length > len){
          this.$Message.warning(this.$t('codekey_list_user_num_max'));
          return;
        }
        params.user_ids = this.allUserData.map((item)=>{
          return item.id;
        }).join(',')
      }else if(this.userMode == this.$t('codekey_list_issue')){
        if(!this.count || this.count == '0'){
          this.$Message.warning(this.$t('codekey_list_num_placeholder'));
          return;
        }
        if(this.count > len){
          this.$Message.warning(this.$t('codekey_list_num_max'));
          return;
        }
        params.count = this.count;
      }
      this.api.codekey.cdkeyCodeCreate(params).then((res)=>{
        if(this.userMode == this.$t('codekey_list_select_user')){
          this.addUserModal = false;
          this.$emit('success')
        }else if(this.userMode == this.$t('codekey_list_issue')){
          this.addUserModal = false;
          // this.$router.push({
          //   name: 'cdkey-detail',
          //   params: {
          //     id:this.curData.id,
          //     tab:'list'
          //   }
          // });

          let params = {
            cdkey_id:this.curData.id,
            batch:res.batch,
            user_id:-1,
            site_id:this.dataDeatil.site.id,
          };
          this.api.codekey.cdkeyCodeExport(params).then((res)=>{
            let uploadParams = {
              file:res.filepath,
            };
            this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
              util.downLoad(res);
              this.$emit('success')
            })
          })
        }
      })
    },
    changeFirstCategory(data){

      if(this.curData.category_id !='0'){

        return;
      }
      console.log(data,'dddddd')
      this.firstCategoryId = data.id;
      this.secondCategoryId = '0';
      this.userCategory = '';
      this.getCategory(this.firstCategoryId,this.secondCategoryId);
    },
    changeSecondCategory(data){
      if(this.curData.second_category_id != '0'){

        return;
      }
      this.secondCategoryId = data.id;
      this.userCategory = '';
      this.getCategory(this.firstCategoryId,this.secondCategoryId);
    },
    cancelUserModal(){
      this.$emit('close')
    },
    goInvitation(){
      this.$router.push({
        path:'/site/invitation'
      })
    },
    changeThirdCategory(){
      this.getUserList();
    }
  },
}
</script>

<style scoped lang="scss">
.add-container{
  .tit{
    margin-bottom: 20px;
    padding: 0 20px;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    >p{
      margin-right: 10px;
      >span{
        color:#2d8cf0;
      }
    }
    >p:nth-child(2){
      font-size: 14px;
    }
  }
  .category-list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div{
      margin-right: 10px;
      padding: 0px 10px;
      cursor: pointer;
    }
    >div.active{
      background-color: #2d8cf0;
      color:#FFFFFF;
      border-radius: 4px;
    }
  }
  .mode-cont{
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #dcdee2;
    .mode-cont-tit{
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .mode-cont-tit >div:nth-child(1){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-left: 10px;
      }
    }
  }
  .mode-cont-r{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .group-box{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 10px;
      }
    }

  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 10px;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drawer-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .drawer-title-btns {
      padding-right: 40px;
    }
  }
}
</style>
